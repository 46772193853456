import React from 'react'
import {Helmet} from 'react-helmet'
import NavBar from './NavBar'
import Footer from './Footer'
import '../sass/app.scss'


export default function Layout({ children }) {
    return (
        <>
            <Helmet htmlAttributes={{lang: 'ru'}} />
            <main>
                <NavBar />
                <div className="content">
                    { children }
                </div>
            </main>
            <Footer />
        </>
    )
}
